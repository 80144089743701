import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { useGetPrecheckHistoryQuery } from 'common/api/post-session-embed';
import ApprovalItem from 'components/approvals/ApprovalItem';
import { setActiveAttendee } from 'common/slice/activeAttendeeSlice';
import { getPostSessionPrechecks } from 'helpers/playback';
import { getIsApprovalRequired } from 'helpers/prechecks';

const ApprovalList = ({ session }) => {
  const dispatch = useAppDispatch();
  const { data: prechecks, isLoading } = useGetPrecheckHistoryQuery({
    session_uuid: session.session_uuid,
  });

  const sortedPrechecks = prechecks && getPostSessionPrechecks(prechecks);

  useEffect(() => {
    if (session.attendee) {
      dispatch(
        setActiveAttendee({
          attendee_id: session.attendee.id,
          attendee_first_name: session.attendee.first_name,
          attendee_last_name: session.attendee.last_name,
        }),
      );
    }
  }, [session]);

  return (
    <>
      {isLoading && (
        <span className="p-4 flex items-center justify-center" role="spinbutton">
          <span className="w-4 h-4 border border-gray-400 rounded-full animate-spin border-r-transparent"></span>
        </span>
      )}
      {sortedPrechecks && (
        <div className="p-2">
          {sortedPrechecks.idChecks.length > 0 && (
            <div className="mt-2 text-xs text-slate-500 flex items-center gap-2">
              <span>Identity Checks</span>
              <div className="h-0.5 bg-slate-200 flex-1"></div>
            </div>
          )}
          {sortedPrechecks.idChecks.map((precheck) => (
            <ApprovalItem
              session_id={session.session_id}
              session_uuid={session.session_uuid}
              key={precheck.id}
              type={precheck.step}
              resourceUrl={precheck.path}
              signedUrl={precheck.resource_url}
              timestamp={precheck.requested_timestamp}
              status={precheck.status}
              reason={precheck.reason}
              faceCaptureUrl={precheck.faceCaptureUrl}
              isApprovalRequired={getIsApprovalRequired(precheck, session.session_type)}
            />
          ))}
          {sortedPrechecks.envChecks.length > 0 && (
            <div className="mt-2 text-xs text-slate-500 flex items-center gap-2">
              <span>Environment Checks</span>
              <div className="h-0.5 bg-slate-200 flex-1"></div>
            </div>
          )}
          {sortedPrechecks.envChecks.map((precheck) => (
            <ApprovalItem
              session_id={session.session_id}
              session_uuid={session.session_uuid}
              key={precheck.id}
              type={precheck.step}
              resourceUrl={precheck.path}
              signedUrl={precheck.resource_url}
              timestamp={precheck.requested_timestamp}
              status={precheck.status}
              reason={precheck.reason}
              faceCaptureUrl={precheck.faceCaptureUrl}
              isApprovalRequired={getIsApprovalRequired(precheck, session.session_type)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ApprovalList;

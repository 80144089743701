import React, { useState } from 'react';
import Input from '../../../common/components/Input';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

interface AdditionalProperty {
  key: string;
  value: string;
}

interface AdditionalPropertiesInputProps {
  value: AdditionalProperty[];
  onChange: (newValue: AdditionalProperty[]) => void;
}

const AdditionalPropertiesInput: React.FC<AdditionalPropertiesInputProps> = ({
  value,
  onChange,
}) => {
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const handleAddProperty = () => {
    if (newKey && newValue) {
      onChange([...value, { key: newKey, value: newValue }]);
      setNewKey('');
      setNewValue('');
    }
  };

  const handleRemoveProperty = (index: number) => {
    const updatedProperties = value.filter((_, i) => i !== index);
    onChange(updatedProperties);
  };

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedProperties = [...value];
    updatedProperties[index].key = e.target.value;
    onChange(updatedProperties);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedProperties = [...value];
    updatedProperties[index].value = e.target.value;
    onChange(updatedProperties);
  };

  return (
    <div className="space-y-4">
      {value.map((property, index) => (
        <div key={index} className="flex space-x-3">
          <Input
            type="text"
            value={property.key}
            onChange={(e) => handleKeyChange(e, index)}
            placeholder="Key"
            className="w-1/2 p-2 border border-gray-300 rounded-md"
          />
          <Input
            type="text"
            value={property.value}
            onChange={(e) => handleValueChange(e, index)}
            placeholder="Value"
            className="w-1/2 p-2 border border-gray-300 rounded-md"
          />
          <button
            type="button"
            onClick={() => handleRemoveProperty(index)}
            className="text-red-800 px-2 py-1"
          >
            <MinusCircleIcon className="h-6 w-6" />
          </button>
        </div>
      ))}
      <div className="flex space-x-3">
        <Input
          type="text"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          placeholder="Key"
          className="w-1/2 p-2 border border-gray-300 rounded-md"
        />
        <Input
          type="text"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          placeholder="Value"
          className="w-full rounded outline-none px-2 py-1 border border-slate-300"
        />
        <button type="button" onClick={handleAddProperty} className="text-emerald-800 px-2 py-1">
          <PlusCircleIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default AdditionalPropertiesInput;

import { isEmpty } from "lodash";

interface Props {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  joinTime: string;
  examWindow: string;
  completionTime: string;
  additionalProperties: object;
  accommodations: object;
}

const CandidateInfo = ({
  firstName,
  middleName,
  lastName,
  email,
  examWindow,
  joinTime,
  completionTime,
  accommodations,
  additionalProperties
}: Props) => {
  const headerStyles = `text-sm text-slate-800 font-medium capitalize`;
  return (
    <div className="grid grid-cols-2 gap-4 text-xs text-slate-700 p-2">
      <div>
        <p className={headerStyles}>First Name</p>
        <p>{firstName || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Middle Name</p>
        <p>{middleName || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Last Name</p>
        <p>{lastName || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Email</p>
        <p className="break-all">{email || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Exam Window</p>
        <p>{examWindow || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Join Time</p>
        <p>{joinTime || '--'}</p>
      </div>
      <div className="col-span-2">
        <p className={headerStyles}>End Time</p>
        <p>{completionTime || '- Yet to -'}</p>
      </div>
      {!isEmpty(accommodations) && (
        <>
          <div className="mt-2 col-span-2 text-xs text-slate-500 flex items-center gap-2">
            <span>Accommodations</span>
            <div className="h-0.5 bg-slate-200 flex-1"></div>
          </div>
          {Object.keys(accommodations).map((key) => (
            <div key={key}>
              <p className={headerStyles}>{key}</p>
              <p>{accommodations[key] || '--'}</p>
            </div>
          ))}
        </>
      )}
      {!isEmpty(additionalProperties) && (
        <>
          <div className="mt-2 col-span-2 text-xs text-slate-500 flex items-center gap-2">
            <span>Additional Properties</span>
            <div className="h-0.5 bg-slate-200 flex-1"></div>
          </div>
          {Object.keys(additionalProperties).map((key) => (
            <div key={key}>
              <p className={headerStyles}>{key}</p>
              <p>{additionalProperties[key] || '--'}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default CandidateInfo;

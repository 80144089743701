import { useEffect, useState } from 'react';
import { FlagIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useAddAlertMutation } from 'common/api/alert';
import DateTimePicker from 'common/components/DateTimePicker';
import { flagReasons } from 'common/components/constant';
import { closeModal } from 'common/slice/modalSlice';
import dayjs from 'dayjs';

interface Props {
  sessionUuid: string;
  slotStartDate: string;
  slotEndDate: string;
}

const AddFlag = ({ sessionUuid, slotStartDate, slotEndDate }: Props) => {
  const date = new Date();
  const dispatch = useAppDispatch();
  const [addAlert] = useAddAlertMutation();
  const [flagValue, setFlagValue] = useState('');
  const [description, setDescription] = useState('');
  const { currentPlayingTime } = useAppSelector((state) => state.playbackVideo);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { sessionCompletedAt } = useAppSelector((state) => state.modal);

  useEffect(() => {
    if (currentPlayingTime) {
      setSelectedDate(new Date(currentPlayingTime));
    } else if (sessionCompletedAt) {
      setSelectedDate(new Date(slotStartDate));
    } else {
      setSelectedDate(new Date());
    }
  }, [currentPlayingTime, slotStartDate, sessionCompletedAt]);

  const [dateError, setDateError] = useState<string>('');

  const handleAddFlagClick = async () => {
    dispatch(closeModal({}));
    const flag = flagReasons.find((a) => a.key === parseInt(flagValue));
    const newDate = selectedDate ? new Date(selectedDate).toISOString() : date.toISOString();
    await addAlert({
      alert_type_id: flag.alert_type_id,
      description: description ? description : undefined,
      session_id: sessionUuid,
      timestamp: newDate,
    });
  };
  const canceModal = () => {
    dispatch(closeModal({}));
  };
  const handleDateChange = (dateValue: Date) => {
    setSelectedDate(dateValue);
    if (dayjs(dateValue).isBetween(slotStartDate, slotEndDate, null, '[]')) {
      setDateError('');
    } else {
      setDateError(
        'Timestamp Out of Range: Please select a time that occurs during the actual session.',
      );
    }
  };
  return (
    <div className="flex gap-2">
      <span className="flex w-10 h-10 p-2 text-blue-800 bg-blue-200 rounded-full">
        <FlagIcon />
      </span>
      <div className="flex flex-col gap-2 text-sm w-full">
        <div className="mt-1.5 font-bold text-lg">Add Flag</div>
        <div className="flex items-center gap-1 text-zinc-500">
          <p>Event Occured:</p>
          <DateTimePicker
            className="w-full"
            selectedValue={selectedDate}
            initialValue={slotStartDate ? new Date(slotStartDate) : new Date()}
            endDate={new Date(slotEndDate)}
            handleDateChange={handleDateChange}
          />
          {dateError && <div className="text-red-500">{dateError}</div>}
        </div>
        <div>
          <select
            className="p-2 border rounded-md w-full"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedValue = e.target.value;
              if (!dateError && selectedValue) {
                setFlagValue(selectedValue);
              } else {
                setFlagValue('');
              }
            }}
            defaultValue=""
          >
            <option value="" disabled>
              Select an option
            </option>
            {flagReasons.map((flag) => (
              <option key={flag.key} value={flag.key}>
                {flag.text}
              </option>
            ))}
          </select>
        </div>
        <textarea
          placeholder="Description(Optional)"
          id="alertDescription"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="p-2 mt-2 border rounded-md resize-none leading-6 text-left placeholder:text-center placeholder:leading-[60px]"
          cols={10}
          rows={3}
          maxLength={120}
        ></textarea>
        <div className="flex justify-end gap-3 mt-5 text-sm">
          <button className="secondary-btn" onClick={canceModal}>
            Cancel
          </button>
          <button className="primary-btn" disabled={!flagValue} onClick={handleAddFlagClick}>
            Add flag
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFlag;

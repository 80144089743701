import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeModal } from 'common/slice/modalSlice';
import { createNewMessage } from 'helpers/messages';
import { handleError } from 'helpers/sentry';
import { toggleSessionPausedInFirebase } from 'helpers/sessionPause';
import { Mixpanel } from '../../Mixpanel';
import { v4 as uuidv4 } from 'uuid';


interface Props {
  sessionId: string;
}

const ResumeModal = ({ sessionId }: Props) => {
  const { id } = useAppSelector((state) => state.currentUser);
  const dispatch = useAppDispatch();

  const resumeCandidate = async () => {
    dispatch(closeModal({}));
    toggleSessionPausedInFirebase(sessionId, false);
    sendChatMessage();
    Mixpanel.track("proctoring_session_resumed");
  };

  const sendChatMessage = async () => {
      try {
        const messageData = {
          created_at: new Date().toISOString(),
          id: uuidv4(),
          user_id: id,
          role: 'proctor',
          text: "Your session has been resumed by the proctor. You may now continue your exam.",
        };
        await createNewMessage(sessionId, messageData);
      } catch (error) {
        handleError(error);
      }
    };

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg text-center">Resume session</p>
        <p className="mb-2 text-zinc-500">Are you sure you want to resume this session? The candidate will be notified, and the session will resume.</p>
        <div className="mt-3 flex text-sm justify-between">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            className="primary-btn"
            onClick={resumeCandidate}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResumeModal;
